import React, { useState } from "react";

import { Button, Image, Progress } from "antd";

import { ApiRespone, FilesAvailable } from "../../../domain/entities/Api";
import { t } from "../../utilities/Message";
import { getResults } from "./Constants";
import TProgressBar from "../ProgressBar";
import { Link } from "react-router-dom";
import { ROUTES_GENERAL } from "../../utilities/Constant";

import ToolTipInfo from "../ToolTipInfo";

import html2canvas from "html2canvas";
import { ReactSVG } from "react-svg";
import { pathServer } from "../../utilities/Function";
import "./style.scss";

interface ResultProps {
  className?: string;
  loading: boolean;
  data: ApiRespone;
  id?: string;
}

const RenderPreview = ({ processedFile, typeFile, loading }) => {
  const file = loading ? "loading" : typeFile;
  const IMAGE_DEFAULT = "/images/loading.png";

  switch (file) {
    case FilesAvailable.video:
      return (
        <video controls className="w-100" autoPlay>
          <source src={processedFile} type="video/mp4" />
        </video>
      );
    case FilesAvailable.image:
      return (
        <Image
          preview={false}
          alt="deep fake"
          src={processedFile || IMAGE_DEFAULT}
          className="w-100"
          fallback={IMAGE_DEFAULT}
        />
      );
    case FilesAvailable.audio:
      return (
        <audio controls className="w-100">
          <source src={processedFile} type={"audio/mp3"} />
          Your browser does not support the audio element.
        </audio>
      );
    case FilesAvailable.text:
      return <p className="fake"> {processedFile}</p>;
    case "loading":
    default:
      if (typeFile === FilesAvailable.video) {
        return (
          <Image
            preview={false}
            alt="deep fake"
            style={file && { filter: "blur(10px)" }}
            src={IMAGE_DEFAULT}
            className="w-100"
          />
        );
      }
      return (
        //@ts-ignore
        <Image
          preview={false}
          alt="deep fake"
          style={file && { filter: "blur(10px)" }}
          src={processedFile || IMAGE_DEFAULT}
          className="w-100"
        />
      );
  }
};

const TResult = (props: ResultProps) => {
  const { data, loading } = props;

  const [loadingClipboard, setLoadingClipboard] = useState(false);

  const percentage =
    data?.subtasks?.length > 0 ? data.subtasks[0].ai_score : "-";
  const typeFile = data?.content_type || localStorage.getItem("typeFile");
  const processedFile = localStorage.getItem("processedFile");

  const { color, className, title, content, result } = getResults(
    percentage,
    typeFile
  );

  const handleCopy = async () => {
    try {
      setLoadingClipboard(true);
      const contentElement = document.getElementById("result");
      const canvas = await html2canvas(contentElement, {
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      // Convertir el canvas a un blob de imagen
      canvas.toBlob(
        (blob) => {
          // Copiar al portapapeles
          navigator.clipboard.write([
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
        },
        "image/png",
        1
      );
    } catch (error) {
      console.error("Error al generar y copiar la imagen:", error);
    } finally {
      setLoadingClipboard(false);
    }
  };

  return (
    <div id={"result"} className={`result ${props.className}`}>
      <div className="result__body">
        <div className="result__body-content">
          <RenderPreview
            processedFile={processedFile}
            typeFile={typeFile}
            loading={loading}
          />
        </div>

        <div className="result__body-info">
          {loading ? (
            <div className="text-center">
              <TProgressBar />
              <p className="mt-1 font-medium-2">{t("app.result.loading")}</p>
              <p className="">{t("app.result.loading_2")}</p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div className="d-flex g-20">
                <Progress
                  type="circle"
                  percent={Number(percentage)}
                  size={160}
                  strokeColor={color}
                  strokeWidth={3}
                  className={className}
                />
                <div className="m-auto">
                  <p style={{ maxWidth: "200px", marginBottom: "20px" }}>
                    {title && t(title)}
                    <br />
                    <strong className="uppercase font-bold">
                      {content && t(content)}
                    </strong>{" "}
                    <br />
                    {result && t(result)}
                  </p>

                  <Link
                    to={ROUTES_GENERAL.HELP}
                    style={{
                      color: "#1890FF",
                      textDecoration: "none",
                      fontSize: ".9rem",
                    }}
                  >
                    {t("app.Header.ScrollDown")}
                  </Link>
                </div>
              </div>
              <ToolTipInfo />

              <div className="d-flex mx-auto mt-1">
                <Button
                  loading={loadingClipboard}
                  style={{ display: "flex" }}
                  size="large"
                  type="primary"
                  onClick={handleCopy}
                  icon={
                    <ReactSVG src={pathServer.PATH_ICONS + "ic_copy.svg"} />
                  }
                >
                  {t("app.Landing.clipboard")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TResult.displayName = "TResult";

TResult.defaultProps = {
  className: "",
};

export default TResult;
